import $ from 'jquery';

window.copyLink = function(el) {
    var input = $(el).parents('.uk-modal-body').find('input')[0];
    input.select();
    document.execCommand("copy");
}

document.addEventListener('tx.search.updated', function () {
    $('img[js-lazyload]').lazy({
        onError: function (el) {
            $(el).attr('src', $(el).data('default'));
        }
    });
    $('#js-custom-scoll').mCustomScrollbar();
});