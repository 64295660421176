import $ from 'jquery';
import moment from 'moment';
moment.locale('th');
export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    $( document ).ready(function() {
      $('body').css('visibility','').addClass('uk-animation-fade uk-animation-fast');
    });
  },
};

